<template>
  <div class="wrapper" style="padding-top:60px;">
      <div v-if="isLoading" class="loading"><a-icon type="loading" /></div>
      <div v-else>
        <p class="title">{{newsDetail.title}}</p>
        <p class="time">{{newsDetail.inTime}}</p>
        <div class="content" v-html="newsDetail.content"></div>
      </div>
  </div>
</template>

<script>
import {fetchNewsDetail} from "../request/api.js"
export default {
  props: {
  },
  data () {
    return {
      id:this.$route.params.id,
      newsDetail:{},
      isLoading:true

    }
  },
  created () {
    console.log("id::",this.id)
    this.getDetail();
  },
  mounted () {},
  watch: {},
  computed: {},
  methods: {
    getDetail(){
      let data ={id:this.id}
      fetchNewsDetail(data).then(res=>{
        this.newsDetail = res.data
        this.isLoading = false

        console.log("res::",res)
      })
    }

  },
  components: {},
}
</script>
<style scoped>
.loading{width:100%;height:400px;text-align:center;line-height:400px}
div{font-size:12px}
.title{font-size:14px;text-align:center;padding-top:10px;font-weight:bold;line-height:150%;width:350px;margin:0 auto;}
.time{font-size:10px;color:#aaa;text-align:center;line-height:150%}
.content{padding:0 10px;line-height:150%}
.content img{padding:8px 0px!important ;display:block;margin:8px auto!important}
</style>